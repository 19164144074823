import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { analyzeFollowUp } from '../services/analysisService';
import './Sidebar.css';

const Sidebar = ({ annotations }) => {
  const [localAnnotations, setLocalAnnotations] = useState(annotations);
  const [followUpStates, setFollowUpStates] = useState({});
  const [followUpQuestions, setFollowUpQuestions] = useState({});

  React.useEffect(() => {
    setLocalAnnotations(annotations);
  }, [annotations]);

  const handleFollowUp = async (annotation) => {
    const question = followUpQuestions[annotation.id];
    if (!question?.trim()) return;

    setFollowUpStates(prev => ({
      ...prev,
      [annotation.id]: { loading: true, streamingAnswer: '' }
    }));

    try {
      await analyzeFollowUp({
        text: annotation.text,
        question,
        previousAnswer: annotation.note,
        language: annotation.language || 'en'
      }, (chunk) => {
        // Update streaming answer
        setFollowUpStates(prev => ({
          ...prev,
          [annotation.id]: {
            loading: true,
            streamingAnswer: chunk
          }
        }));
      });

      // Clear the question input
      setFollowUpQuestions(prev => ({
        ...prev,
        [annotation.id]: ''
      }));
    } catch (error) {
      console.error('Follow-up error:', error);
      setFollowUpStates(prev => ({
        ...prev,
        [annotation.id]: { 
          loading: false, 
          error: 'Failed to get answer' 
        }
      }));
    } finally {
      // Mark as complete but keep the final answer
      setFollowUpStates(prev => ({
        ...prev,
        [annotation.id]: {
          loading: false,
          answer: prev[annotation.id]?.streamingAnswer
        }
      }));
    }
  };

  return (
    <div className="sidebar">
      <h2>Jerry's Explanations</h2>
      <div className="annotations-list">
        {localAnnotations.map((annotation) => (
          <div key={annotation.id} className="annotation-item">
            <div className="selected-text">{annotation.text}</div>
            <div className="explanation">
              <ReactMarkdown>{annotation.note}</ReactMarkdown>
            </div>
            
            {/* Follow-up section */}
            <div className="follow-up-section">
              <input
                type="text"
                placeholder="Ask me another question about this..."
                value={followUpQuestions[annotation.id] || ''}
                onChange={(e) => setFollowUpQuestions(prev => ({
                  ...prev,
                  [annotation.id]: e.target.value
                }))}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleFollowUp(annotation);
                  }
                }}
                className="follow-up-input"
              />
              <button 
                onClick={() => handleFollowUp(annotation)}
                className="follow-up-button"
                disabled={followUpStates[annotation.id]?.loading}
              >
                {followUpStates[annotation.id]?.loading ? 'Asking...' : 'Ask Jerry'}
              </button>
            </div>

            {/* Show streaming answer while loading */}
            {followUpStates[annotation.id]?.loading && followUpStates[annotation.id]?.streamingAnswer && (
              <div className="follow-up-answer streaming">
                <ReactMarkdown>{followUpStates[annotation.id].streamingAnswer}</ReactMarkdown>
              </div>
            )}

            {/* Show final answer when complete */}
            {!followUpStates[annotation.id]?.loading && followUpStates[annotation.id]?.answer && (
              <div className="follow-up-answer">
                <ReactMarkdown>{followUpStates[annotation.id].answer}</ReactMarkdown>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar; 