import React, { useRef, useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import * as pdfjsLib from 'pdfjs-dist';
import ReactMarkdown from 'react-markdown';
import { analyzeText } from '../services/analysisService';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './PDFViewer.css';

// Initialize PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PDFViewer = ({ fileUrl, setAnnotations }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [selectedText, setSelectedText] = React.useState(null);
  const [isAnalyzing, setIsAnalyzing] = React.useState(false);
  const [pdfDocument, setPdfDocument] = React.useState(null);
  const [streamingAnalysis, setStreamingAnalysis] = React.useState('');
  const viewerRef = useRef(null);
  const [language, setLanguage] = React.useState('en');
  
  // Load PDF document when fileUrl changes
  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdf = await pdfjsLib.getDocument(fileUrl).promise;
        setPdfDocument(pdf);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    if (fileUrl) {
      loadPdf();
    }
  }, [fileUrl]);

  // Add text selection handler
  useEffect(() => {
    const handleTextSelection = async (e) => {
      try {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const text = selection.toString().trim();
        if (!text) return;

        // Find the page number from the event target
        let element = e.target;
        let pageNumber = 1;
        while (element && !element.hasAttribute('data-page-number')) {
          element = element.parentElement;
        }
        if (element) {
          pageNumber = parseInt(element.getAttribute('data-page-number'), 10) + 1;
        }

        // Get selection coordinates
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        // Remove any existing help buttons
        document.querySelectorAll('.help-button-container').forEach(el => el.remove());

        // Create help button
        const helpButton = document.createElement('div');
        helpButton.className = 'help-button-container';
        helpButton.style.position = 'fixed';
        helpButton.style.left = `${rect.right}px`;
        helpButton.style.top = `${rect.top}px`;
        helpButton.style.zIndex = '9999';
        helpButton.innerHTML = `
          <button class="help-button">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <circle cx="12" cy="12" r="10"/>
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
              <line x1="12" y1="17" x2="12" y2="17"/>
            </svg>
            Ask Jerry
          </button>
        `;

        // Add click handler for analysis
        const button = helpButton.querySelector('button');
        button.addEventListener('click', async () => {
          helpButton.remove();
          const context = await getSurroundingContext(text, pageNumber);
          analyzeText({
            text,
            pageNumber,
            pdfTitle: fileUrl.split('/').pop(),
            surroundingContext: context,
            language,
          }, (analysisText) => {
            setAnnotations(prev => {
              const existingAnnotation = prev.find(a => 
                a.text === text && a.pageNumber === pageNumber && a.isAnalysis
              );
              
              if (existingAnnotation) {
                return prev.map(a => 
                  a.id === existingAnnotation.id 
                    ? { ...a, note: analysisText }
                    : a
                );
              }

              return [...prev, {
                id: Date.now(),
                text,
                note: analysisText,
                pageNumber,
                isAnalysis: true,
                context,
              }];
            });
          });
        });

        document.body.appendChild(helpButton);

        // Remove button when clicking outside or making new selection
        const handleClickOutside = (event) => {
          if (!helpButton.contains(event.target)) {
            helpButton.remove();
            document.removeEventListener('mousedown', handleClickOutside);
          }
        };

        setTimeout(() => {
          document.addEventListener('mousedown', handleClickOutside);
        }, 0);

      } catch (error) {
        console.error('Error handling text selection:', error);
      }
    };

    const container = document.querySelector('.pdf-viewer-container');
    if (container) {
      container.addEventListener('mouseup', handleTextSelection);
    }

    return () => {
      const container = document.querySelector('.pdf-viewer-container');
      if (container) {
        container.removeEventListener('mouseup', handleTextSelection);
      }
    };
  }, [fileUrl, setAnnotations, language]);

  const getSurroundingContext = async (selectedText, pageNumber) => {
    try {
      if (!pdfDocument) {
        console.log('PDF document not loaded');
        return '';
      }

      // Get the page
      const page = await pdfDocument.getPage(pageNumber);
      const textContent = await page.getTextContent();
      
      // Convert text items to a single string with position information
      const textItems = textContent.items.map(item => ({
        text: item.str,
        x: item.transform[4], // x position
        y: item.transform[5], // y position
      }));

      // Find the selected text position
      const selectedIndex = textItems.findIndex(item => 
        item.text.includes(selectedText) || selectedText.includes(item.text)
      );

      if (selectedIndex === -1) {
        console.log('Selected text not found in page');
        return '';
      }

      // Get surrounding paragraphs (3 items before and after)
      const contextWindow = 3;
      const startIndex = Math.max(0, selectedIndex - contextWindow);
      const endIndex = Math.min(textItems.length - 1, selectedIndex + contextWindow);

      // Group items into paragraphs based on y-position
      let paragraphs = [];
      let currentParagraph = [];
      let lastY = null;
      const yThreshold = 5; // Threshold for considering items part of the same line

      for (let i = startIndex; i <= endIndex; i++) {
        const item = textItems[i];
        
        if (lastY === null || Math.abs(item.y - lastY) <= yThreshold) {
          // Same paragraph
          currentParagraph.push(item.text);
        } else {
          // New paragraph
          if (currentParagraph.length > 0) {
            paragraphs.push(currentParagraph.join(' '));
            currentParagraph = [];
          }
          currentParagraph.push(item.text);
        }
        
        lastY = item.y;
      }
      
      // Add the last paragraph
      if (currentParagraph.length > 0) {
        paragraphs.push(currentParagraph.join(' '));
      }

      // Format the context
      const context = paragraphs.join('\n\n');
      
      console.log('Extracted context:', context);
      return context;

    } catch (error) {
      console.error('Error getting surrounding context:', error);
      return '';
    }
  };

  const handleAnalyze = async () => {
    if (!selectedText) return;
    
    setIsAnalyzing(true);
    setStreamingAnalysis('');
    try {
      const context = await getSurroundingContext(selectedText.text, selectedText.pageNumber);
      
      const { analysis } = await analyzeText({
        text: selectedText.text,
        pageNumber: selectedText.pageNumber,
        pdfTitle: fileUrl.split('/').pop(),
        surroundingContext: context,
        language,
      }, (chunk) => {
        setStreamingAnalysis(chunk);
      });
      
      setAnnotations(prev => [...prev, {
        id: Date.now(),
        text: selectedText.text,
        note: analysis,
        pageNumber: selectedText.pageNumber,
        isAnalysis: true,
        context: context,
      }]);

      setSelectedText(null);
      setStreamingAnalysis('');
    } catch (error) {
      console.error('Failed to analyze text:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="pdf-viewer-container">
      <div className="language-selector">
        <select 
          value={language} 
          onChange={(e) => setLanguage(e.target.value)}
          className="language-select"
        >
          <option value="en">English</option>
          <option value="zh">中文</option>
        </select>
      </div>
      <div className="pdf-content" style={{ height: '100%' }}>
        <Viewer
          ref={viewerRef}
          fileUrl={fileUrl}
          plugins={[
            defaultLayoutPluginInstance,
          ]}
        />
      </div>

      {selectedText && (
        <div className="floating-analyze-button">
          <button 
            onClick={handleAnalyze}
            disabled={isAnalyzing}
            className="analyze-button"
          >
            {isAnalyzing ? 'Analyzing...' : 'Analyze Selection'}
          </button>
          <div className="selected-preview">
            "{selectedText.text.substring(0, 50)}
            {selectedText.text.length > 50 ? '...' : ''}"
          </div>
          {streamingAnalysis && (
            <div className="streaming-analysis markdown-content">
              <ReactMarkdown>{streamingAnalysis}</ReactMarkdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PDFViewer; 