import React, { useState, useCallback } from 'react';
import PDFViewer from './components/PDFViewer';
import Sidebar from './components/Sidebar';
import './App.css';

function App() {
  const [file, setFile] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setFile(URL.createObjectURL(file));
    } else {
      alert('Please upload a PDF file');
    }
  };

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files?.[0];
    if (file && file.type === 'application/pdf') {
      setFile(URL.createObjectURL(file));
    } else {
      alert('Please upload a PDF file');
    }
  }, []);

  return (
    <div className="app">
      {!file ? (
        <div className="landing-page">
          <div className="content">
            <h1 className="title">From Jerry, With Love ❤️</h1>
            
            <div className="upload-section">
              <h2>Hey Cici!</h2>
              <p>Upload any PDF you're reading, and I'll help explain it to you 💕</p>
              <div 
                className={`upload-area ${isDragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="upload-content">
                  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="upload-icon">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                    <polyline points="17 8 12 3 7 8" />
                    <line x1="12" y1="3" x2="12" y2="15" />
                  </svg>
                  <p className="upload-text">Drag and drop your PDF here</p>
                  <p className="upload-text-small">or</p>
                  <label className="file-input-label">
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileChange}
                      className="file-input"
                    />
                    <span className="upload-button">Choose a file</span>
                  </label>
                </div>
              </div>
              <p className="upload-hint">Just like how you share your thoughts with me, Cici 💕</p>
            </div>

            <div className="features-grid">
              <div className="feature">
                <h3>Always Here For You, Cici</h3>
                <p>Select any confusing text, and I'll explain it in a way that makes sense to you</p>
              </div>
              <div className="feature">
                <h3>💡 Clear & Simple</h3>
                <p>Complex concepts broken down into simple explanations, just like our study sessions together</p>
              </div>
              <div className="feature">
                <h3>❤️ Your Personal Study Buddy</h3>
                <p>Whether it's day or night, I'm here to help you understand anything you're reading, my dear</p>
              </div>
            </div>

            <div className="how-it-works">
              <h2>How to Use Your Gift, Cici</h2>
              <div className="steps">
                <div className="step">
                  <div className="step-number">1</div>
                  <p>Upload any PDF you're studying</p>
                </div>
                <div className="step">
                  <div className="step-number">2</div>
                  <p>Select the text you want me to explain</p>
                </div>
                <div className="step">
                  <div className="step-number">3</div>
                  <p>Click "Ask Jerry" and I'll break it down for you</p>
                </div>
              </div>
            </div>

            <footer className="love-footer">
              <p>Made with 💖 for my Cici</p>
              <p className="ps">P.S. You can switch to Chinese explanations too, sweetie!</p>
            </footer>
          </div>
        </div>
      ) : (
        <div className="workspace">
          <div className="main-content">
            <PDFViewer fileUrl={file} setAnnotations={setAnnotations} />
          </div>
          <Sidebar annotations={annotations} />
        </div>
      )}
    </div>
  );
}

export default App; 