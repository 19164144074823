const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? '/api'  // In production, use relative path
  : 'http://localhost:3001/api'; // In development, use localhost

export const analyzeText = async ({ text, pageNumber, pdfTitle, surroundingContext, language }, onChunk) => {
  try {
    console.log('analyzeText called with:', { text, pageNumber, pdfTitle, surroundingContext });
    
    if (!text || text.trim().length === 0) {
      throw new Error('No text provided for analysis');
    }

    const response = await fetch(`${API_BASE_URL}/analyze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        text: text.trim(), 
        pageNumber,
        pdfTitle,
        surroundingContext,
        language,
      }),
    });

    if (!response.ok) {
      throw new Error('Analysis request failed');
    }

    // Create event source for streaming
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let analysisText = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const data = JSON.parse(line.slice(6));
            if (data.type === 'chunk' && data.text) {
              analysisText += data.text;
              onChunk?.(analysisText);
            }
          } catch (e) {
            console.error('Error parsing SSE data:', e);
          }
        }
      }
    }

    return { analysis: analysisText };
  } catch (error) {
    console.error('Error in analyzeText:', error);
    throw error;
  }
};

export const analyzeFollowUp = async ({ text, question, previousAnswer, language }, onChunk) => {
  try {
    const response = await fetch(`${API_BASE_URL}/follow-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        text,
        question,
        previousAnswer,
        language,
      }),
    });

    if (!response.ok) {
      throw new Error('Follow-up request failed');
    }

    // Create event source for streaming
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let analysisText = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const data = JSON.parse(line.slice(6));
            if (data.type === 'chunk' && data.text) {
              analysisText += data.text;
              onChunk?.(analysisText);
            }
          } catch (e) {
            console.error('Error parsing SSE data:', e);
          }
        }
      }
    }

    return analysisText;
  } catch (error) {
    console.error('Error in analyzeFollowUp:', error);
    throw error;
  }
}; 